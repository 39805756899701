.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // padding: 0 5em;

  @media screen and (min-width: $screen-m) {
    // padding: 0 2em;
  }

  article {
    padding: 2em 0;
    text-align: left;

    @media screen and (min-width: $screen-sm) {
      padding: 2em;
    }
  }

  figure {
    position: relative;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    background: #fff;
    padding: 0.5em;
    min-width: 33%;
    max-width: 50%;
    margin-top: 0.5em;
    
    h1 {
      font-size: $text-md;
      line-height: $text-xl;
      color: $gray-800;
      font-weight: 700;
    }

    p {
      color: $gray-700;
    }
  }
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0 2.5em 0;
  padding: 1em 0;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  
  .logo {
    img {
      width: 80px;
    }
  }
  
  li {
    display: inline-block;
    
    a {
      text-decoration: none;
      color: $gray-700;
      display: inline-block;
      margin-left: 0.5em;
      padding: 0.7em 1em;

      &:hover {
        outline: 1px solid $gray-400;
      }

      &.active {
        outline: 1px solid $gray-600;
      }
    }
  }
}
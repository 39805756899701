.work {
  h3 {
    margin-bottom: 1em;
  }

  .image {
    cursor: pointer;
  }
}

.headline {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  .text {
    h4 {
      margin-bottom: 1em;
    }
    
    p {
      line-height: 2em;
    }
  }
}
main.about {
  section {
    display: flex;
    text-align: left;
    justify-content: center;
    flex-wrap: wrap;

    > div {
      padding: 2em;
    }
  }
}

$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$text-base-size: 1em;
$text-scale-ratio: 1.15;

$text-xs: calc(1em / #{$text-scale-ratio} * #{$text-scale-ratio});
$text-sm: calc(1em / #{$text-scale-ratio});
$text-md: calc(1em * #{$text-scale-ratio});
$text-lg: calc(1em * #{$text-scale-ratio} * #{$text-scale-ratio});
$text-xl: calc(1em * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio});
$text-xxl: calc(1em * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio});
$text-xxxl: calc(1em * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio} * #{$text-scale-ratio});

$screen-sm: 480px;
$screen-m: 768px;
$screen-l: 1024px;
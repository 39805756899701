@import './reset.scss';
@import './shared/variables.scss';
@import './shared/grid.scss';
@import './components/Image/Image.scss';
@import './components/Header/Header.scss';
@import './components/Works/Works.scss';
@import './components/Work/Work.scss';
@import './components/Work/Gallery.scss';
@import './components/About/About.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Source+Sans+Pro&display=swap');

body {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1.2em;
	color: $gray-800;
	padding: 1em;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 700;
	color: $gray-800;
	text-transform: uppercase;
}

h1, h2, h3 {
	font-weight: 100;
}

h1 {
	font-size: $text-xxxl;	
	line-height: $text-xxxl;	
}
h2 {
	font-size: $text-xxl;	
	line-height: $text-xxl;	
}
h3 {
	font-size: $text-xl;	
	line-height: $text-xl;	
}
h4 {
	font-size: $text-lg;	
	line-height: $text-lg;	
}
h5 {
	font-size: $text-md;	
	line-height: $text-md;	
}
h6 {
	font-size: $text-sm;	
	line-height: $text-sm;	
}
p  {
	font-size: $text-xs;	
	line-height: $text-lg;	
}

main {
	text-align: center;
}

.works {
  article {
    .image:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      opacity: 0;
      transition: all 0.3s linear;
    }

    &:hover {
      .image:after {
        opacity: 0.2;
      }
    }
  }

  .photographer {
    margin-top: 24px;
    font-size: 0.9em;
  }
}
